:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --warning-color: #ffc107;
  --info-color: #17a2b8;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --background-gradient: linear-gradient(135deg, #f8f9fa, #e9ecef);
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 100px;
  transition: transform 0.3s;
}

.logo:hover {
  transform: scale(1.1);
}

.subtitle {
  font-style: italic;
  font-size: x-large;
  color: var(--primary-color);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.headshot {
  height: 400px;
  width: 300px;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s;
}

.headshot:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.aboutMeTitle {
  margin-bottom: 20px;
  color: var(--primary-color);
  text-align: center;
}

.contactTitle {
  margin-bottom: 20px;
  color: var(--primary-color);
  text-align: center;
}

.skillsTitle {
  margin-bottom: 20px;
  color: var(--primary-color);
  text-align: center;
}

.skillsTitle ul {
  list-style-type: none;
  padding: 0;
}

.skillsTitle li {
  margin: 5px 0;
  font-size: 1.2rem;
}

.githubRepos {
  margin-top: 40px;
  padding: 20px;
  background-color: var(--light-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}

.table-hover tbody tr:hover {
  background-color: var(--light-color);
}

.navbar {
  margin-bottom: 20px;
  background-color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-menu {
  display: flex;
  gap: 20px;
}

.navbar a {
  margin: 0 15px;
  text-decoration: none;
  color: var(--light-color);
  transition: color 0.3s;
  font-weight: bold;
}

.navbar a:hover {
  text-decoration: underline;
  color: var(--dark-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-gradient);
  color: var(--dark-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: var(--secondary-color);
  padding: 2px 4px;
  border-radius: 4px;
  color: var(--light-color);
  box-shadow: var(--box-shadow);
}